body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-tabs-card >.ant-tabs-nav .ant-tabs-tab {
  border: none;
}

/* This class hides the element when the screen width is 768 pixels or less */
.hide-on-small-screen {
  display: none;
}

@media (min-width: 769px) {
  .hide-on-small-screen {
    display: initial;
  }
}
